import { useTheme } from "@emotion/react";
import { endOfWeek, startOfWeek } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";
import React, { useEffect } from "react";
import { TooltipProps } from "recharts";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { ChartType, TimeGranularity } from "../../constants/enums";
import Box from "../components/Box";
import Flex from "../components/Flex";
import Text from "../components/Text";
import ChartDataManager from "../utils/ChartDataManager";
import { ReadableKeys } from "./types";
import { formatMeasureValueWithUnit } from "./utils";

interface Props extends TooltipProps<ValueType, NameType> {
  dataManager: ChartDataManager;
  getCursorValue: (cursorY: number) => number;
  getCursorValueX?: (cursorY: number) => number;
  granularity?: TimeGranularity;
  payload?: (Payload<ValueType, NameType> & { fill?: string })[];
  readableKeys?: ReadableKeys;
  isClusteredBar?: boolean;
  tooltipDataKey?: string;
  chartType: ChartType;
  setTooltipDataKey: (dataKey: string) => void;
  xAxisFormatter: (value: string) => string;
}

export default function ExperimentalTooltip(props: Props) {
  const theme = useTheme();

  const coordinateY =
    props.coordinate && props.coordinate.y ? props.coordinate.y : 0;

  const cursorValue = props.getCursorValue(coordinateY);

  let currentTotal = 0;

  const payload = props.payload ?? [];

  const points = payload.map((object) => {
    const dataKey = String(object.dataKey);

    const point = {
      color: object.color,
      dataKey: dataKey,
      fill: object.fill,
      name: String(object.name),
      start: currentTotal,
      startX: 0,
      stroke: object.stroke,
      value: object.payload[dataKey],
    };

    if (
      props.chartType == ChartType.STACKED_AREA ||
      props.chartType == ChartType.STACKED_BAR
    ) {
      currentTotal += object.payload[dataKey];
    }

    return point;
  });

  type Point = (typeof points)[number];

  const nearestPoint = points.reduce<null | (Point & { deltaValue: number })>(
    (accum, point) => {
      switch (props.chartType) {
        case ChartType.STACKED_AREA:
        case ChartType.STACKED_BAR:
          if (
            cursorValue > point.start &&
            cursorValue < point.start + point.value
          ) {
            console.log("hello", point.value);
            return {
              ...point,
              deltaValue: point.start + point.value - cursorValue,
            };
          } else {
            return accum;
          }

        case ChartType.CLUSTERED_BAR:
          if (props.tooltipDataKey && point.dataKey == props.tooltipDataKey) {
            return {
              ...point,
              deltaValue: point.value,
            };
          } else {
            return accum;
          }

        case ChartType.LINE:
          const deltaValue = Math.abs(point.value - cursorValue);

          if (!accum) {
            return { ...point, deltaValue };
          }

          if (Math.abs(deltaValue) < Math.abs(accum.deltaValue)) {
            return { ...point, deltaValue };
          }

          return accum;

        case ChartType.AREA:
          if (!accum) {
            return { ...point, deltaValue: point.value };
          }

          if (cursorValue < point.value) {
            return { ...point, deltaValue: point.value };
          }

          return accum;
        default:
          return null;
      }
    },
    null
  );

  useEffect(() => {
    if (nearestPoint?.dataKey) {
      props.setTooltipDataKey(nearestPoint.dataKey);
    }
  }, [nearestPoint?.dataKey ?? null]);

  if (payload.length === 0 || !nearestPoint) {
    return null;
  }
  const name = props.dataManager.getDimensionValues(nearestPoint.name);
  const measureKey = props.dataManager.getMeasureKey(nearestPoint.name);

  const measure = props.dataManager.measures.find(
    (measure) => measure.name === measureKey
  );

  const measureString = formatMeasureValueWithUnit({
    value: nearestPoint.value,
    unit: measure?.unit,
  });

  const readableKey = props.readableKeys
    ? props.readableKeys[measureKey]
    : measureKey;

  let dateLabel;

  const utcDate = toZonedTime(new Date(props.label), "UTC");

  switch (props.granularity) {
    case TimeGranularity.HOUR: {
      dateLabel = format(utcDate, "iii LLL d, y pp");
      break;
    }
    case TimeGranularity.DAY: {
      dateLabel = format(utcDate, "iii LLL d, y");
      break;
    }
    case TimeGranularity.WEEK: {
      const start = startOfWeek(utcDate);
      const end = endOfWeek(utcDate);

      dateLabel = `${format(start, "LLL d")} - ${format(end, "LLL d, y")}`;
      break;
    }
    case TimeGranularity.MONTH: {
      dateLabel = format(utcDate, "LLL y");
      break;
    }
    case TimeGranularity.QUARTER: {
      dateLabel = format(utcDate, "QQQ y");
      break;
    }
    case undefined: {
      dateLabel = "";
    }
  }

  return (
    <Box pointerEvents="none" padding={theme.space_xs}>
      <Flex
        alignItems="center"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        boxShadow={`0 0 0 2px ${theme.border_color}`}
        height={120}
        padding={theme.space_sm}
        pointerEvents="auto"
      >
        <Box
          backgroundColor={nearestPoint.color}
          borderRadius={theme.borderRadius_2}
          height="100%"
          marginRight={theme.space_xs}
          width={5}
        />
        <Box height="90%">
          <Text bold>{readableKey}</Text>
          <Text>{name}</Text>
          <Text
            fontSize={theme.fontSize_small}
            marginVertical={theme.space_xxs}
          >
            {dateLabel}
          </Text>
          <Text>{measureString}</Text>
        </Box>
      </Flex>
    </Box>
  );
}
