import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import { noop, truncate } from "lodash";
import React, { useState, useTransition } from "react";
import {
  Area,
  CartesianGrid,
  Customized,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart as _AreaChart,
  Legend as _Legend,
} from "recharts";
import { fiscalGranularities } from "../../analytics/fiscalDateUtils";
import {
  DEFAULT_CHART_GROUPINGS_LIMIT,
  UnitType,
} from "../../constants/analytics";
import { ChartType, Operator, TimeGranularity } from "../../constants/enums";
import { ActivityTracker, actions } from "../../telemetry";
import {
  areaStyleProps,
  cartesianStyleProps,
  lineStyleProps,
  xAxisStyleProps,
  yAxisStyleProps,
} from "../charts/styles";
import {
  Dimension,
  Filter,
  Measure,
  RawData,
  ReadableKeys,
} from "../charts/types";
import {
  DEFAULT_X_AXIS_KEY,
  NOT_SHOWN_KEY,
  PERCENTAGE_Y_AXIS_ID,
  REPORT_PDF_CHART_HEIGHT,
  formatMeasureValueWithUnit,
  formatTimestamp,
  getColorByReverseIndex,
  getGreenColors,
  getIsDashedMeasure,
  getTicks,
  getUniformUnitType,
} from "../charts/utils";
import Box from "../components/Box";
import EmptyPlaceholder from "../components/EmptyPlaceholder";
import copyText from "../copyText";
import { ECO_DATA_VIZ_COLORS } from "../theme/default";
import { useChartDataManager } from "../utils/ChartDataManager";
import { getFormatForGranularity } from "../utils/dates";
import { ChartWrapper } from "./ChartWrapper";
import ExperimentalTooltip from "./ExperimentalTooltip";
import Legend from "./Legend";
import LegendSimple from "./LegendSimple";
import LegendTable from "./LegendTable";
import { getTooltip } from "./TooltipTable";
import { useHorizontalLine, useTooltipCollector } from "./TooltipUtils";
import YAxisLabel from "./YaxisLabel";
import YAxisTick from "./YAxisTick";

interface Props {
  activityTracker?: ActivityTracker;
  currencyCode?: string;
  customColors?: string[];
  data: RawData[];
  dimensions: Dimension[];
  disableDrilldown?: boolean;
  excludeOther?: boolean;
  hideSubtotal?: boolean;
  hideTotal?: boolean;
  isDataSorted?: boolean;
  isEcoImpactMode?: boolean;
  isFiscalMode?: boolean;
  isLoading: boolean;
  isServer?: boolean;
  limit?: number | null;
  measures: Measure[];
  mergeMeasures?: boolean;
  readableKeys?: ReadableKeys;
  showExperimentalTooltip?: boolean;
  showLegend?: boolean;
  showTooltip?: boolean;
  stacked?: boolean;
  timeSeriesGranularity: TimeGranularity;
  tooltipLimit?: number;
  xAxisKey?: string;
  yAxisLabel?: string;
  tooltipFormatter?: (value: any, grouping: string) => string;
  xAxisFormatter?: (value: any) => string;
  yAxisFormatter?: (value: any) => string;
  onInteraction?: (interaction: AreaChart.Interaction) => void;
}

const StyledBox = styled(Box)`
  .recharts-legend-wrapper {
    max-height: 8rem;
    overflow-y: auto;
  }

  .recharts-legend-item {
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: ${({ theme }) => theme.space_xs};

    .recharts-symbols {
      d: path("M -8 -16 h 32 v 32 h -32 Z");
    }
  }

  span.recharts-legend-item-text {
    color: ${(props) => props.theme.text_color} !important;
    display: block;
    font-size: ${({ theme }) => theme.fontSize_ui};
    /* Bring in the below if legend key lengths become an issue */
    /* max-width: 40rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }

  .recharts-default-legend {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;

    li {
      cursor: pointer;
    }
  }
`;

const AXIS_CHARACTER_WIDTH = 7;
const AXIS_MAX_LEFT_MARGIN = AXIS_CHARACTER_WIDTH * 10;
const DEFAULT_MAX_GROUPINGS_TO_DISPLAY = DEFAULT_CHART_GROUPINGS_LIMIT + 1; // The rest will go into the OTHER category to maintain an accurate stack total

const STACK_ID = "STACK_ID";
const DEFAULT_Y_AXIS_ID = "DEFAULT_Y_AXIS_ID";

function AreaChart(props: Props): JSX.Element {
  const theme = useTheme();

  const MAX_GROUPINGS_TO_DISPLAY = props.limit
    ? props.limit
    : DEFAULT_MAX_GROUPINGS_TO_DISPLAY;

  const xAxisKey = props.xAxisKey ?? DEFAULT_X_AXIS_KEY;

  const [isPending, startTransition] = useTransition();

  const [excludedKeys, setExcludedKeys] = useState<string[]>([]);
  const [tooltipDataKey, setTooltipDataKey] = useState<string | undefined>(
    undefined
  );

  //
  // Custom Elements
  //

  const horizontalLineElement = useHorizontalLine();
  const [tooltipCollectorElement, getCursorValue] =
    useTooltipCollector(DEFAULT_Y_AXIS_ID);

  //
  // Data
  //

  const dataManager = useChartDataManager({
    data: props.data,
    dimensions: props.dimensions,
    excludedChartKeys: excludedKeys,
    excludeOther: props.excludeOther,
    isDataSorted: props.isDataSorted,
    maxGroupingCount: MAX_GROUPINGS_TO_DISPLAY,
    measures: props.measures,
    mergeMeasures: props.mergeMeasures,
    xAxisKey,
  });

  const uniformAreaMeasureUnit = getUniformUnitType(props.measures);

  const isImpactMode = props.isEcoImpactMode ?? false;
  const dynamicAxisColors = getGreenColors(isImpactMode, theme);
  const chartColors = isImpactMode
    ? ECO_DATA_VIZ_COLORS
    : props.customColors
      ? props.customColors
      : theme.data_visualization_colors;

  //
  // Handlers
  //

  function handleClickArea(chartKey: string) {
    const dimensionsWithValues =
      dataManager.getDimensionValuesFromChartKey(chartKey);
    const measure = dataManager.getMeasure(chartKey);

    if (
      !props.onInteraction ||
      !measure ||
      (dataManager.dimensions.length === 0 && dataManager.measures.length === 1)
    ) {
      return;
    }

    if (
      dataManager.dimensions.length === 0 &&
      dataManager.measures.length > 1
    ) {
      props.onInteraction({
        filters: measure.name,
        type: AreaChart.INTERACTION_ADD_MEASURES_FILTER_CLICKED,
      });
      return;
    }

    props.onInteraction({
      filters: dimensionsWithValues.map(({ dimension, value }) => ({
        name: dimension.name,
        operator: Operator.EQUALS,
        values: [value],
      })),
      type: AreaChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED,
    });
  }

  function handleInteraction(
    interaction: LegendSimple.Interaction | LegendTable.Interaction
  ) {
    switch (interaction.type) {
      case LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED:
      case LegendTable.INTERACTION_CHART_EXCLUSION_CHANGED: {
        if (props.activityTracker) {
          props.activityTracker.captureAction(
            actions.TOGGLE_CHART_LEGEND_ITEM,
            { keys: interaction.excludedChartKeys }
          );
        }

        setExcludedKeys(interaction.excludedChartKeys);
        break;
      }
      default:
        break;
    }
  }

  //
  // JSX
  //

  if (props.isLoading || props.data.length === 0) {
    return (
      <EmptyPlaceholder
        loading={props.isLoading}
        icon={faChartArea}
        skeletonVariant="cartesian"
        text={copyText.chartEmptyPlaceholderText}
      />
    );
  }

  let leftMargin =
    AXIS_CHARACTER_WIDTH *
    Math.round(dataManager.maxValue ?? 100).toString().length;
  if (leftMargin > AXIS_MAX_LEFT_MARGIN) {
    leftMargin = AXIS_MAX_LEFT_MARGIN;
  }

  const dateFormat = getFormatForGranularity(props.timeSeriesGranularity);

  const xAxisFormatter = (value: string) =>
    props.xAxisFormatter
      ? props.xAxisFormatter(value)
      : xAxisKey === DEFAULT_X_AXIS_KEY &&
          (!props.isFiscalMode ||
            !fiscalGranularities.includes(props.timeSeriesGranularity))
        ? formatTimestamp(value, dateFormat)
        : props.isServer && xAxisKey !== DEFAULT_X_AXIS_KEY
          ? truncate(value, { length: 25 })
          : value;

  return (
    <StyledBox
      backgroundColor={theme.panel_backgroundColor}
      height="100%"
      position="relative"
      width="100%"
    >
      <ChartWrapper isServer={props.isServer}>
        <_AreaChart
          data={dataManager.chartData}
          height={props.isServer ? REPORT_PDF_CHART_HEIGHT : undefined}
          margin={{
            top: props.isServer ? 10 : undefined,
            left: leftMargin,
            right: 25,
          }}
          width={props.isServer ? 750 : undefined}
        >
          {tooltipCollectorElement}
          <CartesianGrid
            {...cartesianStyleProps}
            stroke={theme.chart_cartesian_grid_lines}
          />
          <XAxis
            {...xAxisStyleProps}
            stroke={dynamicAxisColors.axis}
            tick={{
              stroke: theme.chart_axis_text,
              fontWeight: 100,
              fontSize: "0.8rem",
            }}
            dataKey={xAxisKey}
            ticks={
              props.isServer
                ? getTicks(dataManager.chartData, xAxisKey)
                : undefined
            }
            tickFormatter={xAxisFormatter}
          />
          {props.yAxisLabel && (
            <Customized component={<YAxisLabel value={props.yAxisLabel} />} />
          )}
          <YAxis
            domain={[0, "auto"]}
            {...yAxisStyleProps}
            stroke={dynamicAxisColors.axis}
            tick={<YAxisTick />}
            tickCount={8}
            tickFormatter={(value) =>
              props.yAxisFormatter
                ? props.yAxisFormatter(value)
                : formatMeasureValueWithUnit({
                    currencyCode: props.currencyCode,
                    unit: uniformAreaMeasureUnit,
                    value,
                  })
            }
            yAxisId={DEFAULT_Y_AXIS_ID}
          />
          <YAxis domain={[0, 100]} hide yAxisId={PERCENTAGE_Y_AXIS_ID} />
          {[...dataManager.sortedChartKeys]
            .map(
              (key, index) =>
                [
                  key,
                  index,
                  getIsDashedMeasure(dataManager.getMeasure(key)?.name),
                ] as const
            )
            .sort(([_a, aIndex, isADashed], [_b, bIndex, isBDashed]) => {
              // ensure dashed lines are always drawn after (in front of) areas

              if (isADashed === isBDashed) {
                // preserve order if both dashed / not-dashed
                return Math.sign(aIndex - bIndex);
              }
              if (isADashed) return 1;
              return -1;
            })
            .map(([key, i, isDashedMeasure]) => {
              const excluded = excludedKeys.includes(key);
              const color = excluded
                ? theme.background_color_disabled
                : getColorByReverseIndex(
                    dataManager.sortedChartKeys.length,
                    i,
                    chartColors
                  );
              const measure = dataManager.getMeasure(key);
              const isPercentage = measure?.unit === UnitType.PERCENTAGE;

              if (isDashedMeasure) {
                return (
                  <Line
                    {...lineStyleProps}
                    activeDot={{ style: { cursor: "not-allowed" } }}
                    key={key}
                    dataKey={key}
                    stroke={color}
                    strokeDasharray={"5 5"}
                    {...(excluded ? { strokeWidth: 0 } : { strokeWidth: 2.5 })}
                    yAxisId={
                      isPercentage ? PERCENTAGE_Y_AXIS_ID : DEFAULT_Y_AXIS_ID
                    }
                    style={{
                      cursor: "not-allowed",
                    }}
                    onMouseOver={() =>
                      startTransition(() => setTooltipDataKey(key))
                    }
                    onMouseLeave={() =>
                      startTransition(() => setTooltipDataKey(undefined))
                    }
                  />
                );
              }

              return (
                <Area
                  {...areaStyleProps}
                  key={key}
                  dataKey={key}
                  fill={color}
                  fillOpacity={theme.chart_fill_opacity}
                  stackId={props.stacked ? STACK_ID : undefined}
                  stroke={color}
                  style={{
                    cursor:
                      props.disableDrilldown || key.includes(NOT_SHOWN_KEY)
                        ? "not-allowed"
                        : props.dimensions.length > 0 ||
                            props.measures.length > 0
                          ? "pointer"
                          : "normal",
                  }}
                  yAxisId={DEFAULT_Y_AXIS_ID}
                  onClick={
                    props.disableDrilldown || key.includes(NOT_SHOWN_KEY)
                      ? noop
                      : () => handleClickArea(key)
                  }
                  onMouseOver={() =>
                    startTransition(() => setTooltipDataKey(key))
                  }
                  onMouseLeave={() =>
                    startTransition(() => setTooltipDataKey(undefined))
                  }
                  {...(excluded ? { strokeWidth: 0 } : {})}
                />
              );
            })}
          {props.showTooltip &&
            !props.showExperimentalTooltip &&
            getTooltip(
              {
                dataManager: dataManager,
                hideSubtotal: props.hideSubtotal,
                hideTotal: props.hideTotal,
                hoveredChartKey: isPending ? null : (tooltipDataKey ?? null),
                isImpactMode: isImpactMode,
                maxRows: props.tooltipLimit,
                readableKeys: props.readableKeys,
                xAxisFormatter: xAxisFormatter,
              },
              theme
            )}
          {horizontalLineElement}
          {props.showLegend && (
            <_Legend
              content={
                <Legend
                  dataManager={dataManager}
                  isServer={props.isServer}
                  readableKeys={props.readableKeys}
                  onInteraction={handleInteraction}
                />
              }
              verticalAlign="bottom"
              wrapperStyle={
                props.isServer
                  ? { overflow: "visible", position: "relative" }
                  : undefined
              }
            />
          )}
          {props.showExperimentalTooltip && (
            <Tooltip
              content={
                <ExperimentalTooltip
                  chartType={
                    props.stacked ? ChartType.STACKED_AREA : ChartType.AREA
                  }
                  dataManager={dataManager}
                  getCursorValue={getCursorValue}
                  granularity={
                    props.xAxisKey !== DEFAULT_X_AXIS_KEY
                      ? undefined
                      : props.timeSeriesGranularity
                  }
                  setTooltipDataKey={(dataKey) =>
                    startTransition(() => setTooltipDataKey(dataKey))
                  }
                  xAxisFormatter={xAxisFormatter}
                />
              }
              cursor={false}
              offset={0}
            />
          )}
        </_AreaChart>
      </ChartWrapper>
    </StyledBox>
  );
}

AreaChart.defaultProps = {
  dimensions: [],
};

AreaChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED =
  `AreaChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED` as const;

AreaChart.INTERACTION_ADD_MEASURES_FILTER_CLICKED =
  `AreaChart.INTERACTION_ADD_MEASURES_FILTER_CLICKED` as const;

interface InteractionAddGroupingFilterClicked {
  type: typeof AreaChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED;
  filters: Filter[];
}
interface InteractionAddMeasuresFilterClicked {
  type: typeof AreaChart.INTERACTION_ADD_MEASURES_FILTER_CLICKED;
  filters: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AreaChart {
  export type Interaction =
    | InteractionAddGroupingFilterClicked
    | InteractionAddMeasuresFilterClicked;
}

export default AreaChart;
