import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faPieChart } from "@fortawesome/free-solid-svg-icons";
import { noop } from "lodash";
import React, { useMemo } from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart as ReactPieChart,
  Tooltip,
} from "recharts";
import { Operator } from "../../constants/enums";
import {
  ChartDatum,
  Dimension,
  Filter,
  Measure,
  RawData,
  ReadableKeys,
} from "../charts/types";
import {
  NOT_SHOWN_KEY,
  REPORT_PDF_CHART_HEIGHT,
  REPORT_PDF_CHART_WIDTH,
  SLICE_LABEL_KEY,
  SLICE_VALUE_KEY,
  formatMeasureValueWithUnit,
  getColorByReverseIndex,
  getPieChartDataFromRawData,
  getUniformUnitType,
} from "../charts/utils";
import Box from "../components/Box";
import EmptyPlaceholder from "../components/EmptyPlaceholder";
import Flex from "../components/Flex";
import Text from "../components/Text";
import copyText from "../copyText";
import { ECO_DATA_VIZ_COLORS } from "../theme/default";
import { formatPercentage } from "../utils/formatNumber";
import { ChartWrapper } from "./ChartWrapper";

interface Props {
  currencyCode?: string;
  customColors?: string[];
  data: RawData[];
  dimensions: Dimension[];
  disableDrilldown?: boolean;
  hideTotal?: boolean;
  isEcoImpactMode?: boolean;
  isFiscalMode?: boolean;
  isLoading: boolean;
  isServer?: boolean;
  limit?: number | null;
  measures: Measure[];
  readableKeys?: ReadableKeys;
  showExperimentalTooltip?: boolean;
  showFullLabel?: boolean;
  showLegend?: boolean;
  showTooltip?: boolean;
  formatter?: (value: any) => string;
  onInteraction?: (interaction: PieChart.Interaction) => void;
}

const StyledBox = styled(Box)`
  .recharts-legend-item {
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: ${({ theme }) => theme.space_xs};

    .recharts-symbols {
      d: path("M -8 -16 h 32 v 32 h -32 Z");
    }
  }

  span.recharts-legend-item-text {
    color: ${(props) => props.theme.text_color} !important;
    display: block;
    font-size: ${({ theme }) => theme.fontSize_ui};
    /* Bring in the below if legend key lengths become an issue */
    /* max-width: 40rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }

  .recharts-default-legend {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
`;

const MAX_ALLOWED_DATA_LENGTH = 50_000;

function PieChart(props: Props): JSX.Element {
  const theme = useTheme();

  //
  // Data
  //

  const isTooLargeToRender = props.data.length >= MAX_ALLOWED_DATA_LENGTH;

  const [chartData, overallTotal] = useMemo((): [ChartDatum[], number] => {
    if (isTooLargeToRender) {
      return [[], 0];
    }

    return getPieChartDataFromRawData({
      data: props.data,
      dimensions: props.dimensions,
      measures: props.measures,
    });
  }, [props.data, props.dimensions, props.measures, props.limit]);

  const isImpactMode = props.isEcoImpactMode ?? false;

  //
  // Handlers
  //

  function handleClickArea(keyString: string) {
    if (
      !props.onInteraction ||
      props.dimensions.length + props.measures.length === 1
    ) {
      return;
    }

    // Example key => Analytics
    if (props.measures.length === 1 && props.dimensions.length === 1) {
      props.onInteraction({
        filters: [
          {
            name: props.dimensions[0].name,
            operator: Operator.EQUALS,
            values: [keyString],
          },
        ],
        type: PieChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED,
      });
      return;
    }

    if (props.dimensions.length === 0 && props.measures.length > 0) {
      props.onInteraction({
        filters: keyString,
        type: PieChart.INTERACTION_ADD_MEASURES_FILTER_CLICKED,
      });
      return;
    }

    // Example key => cost: category:Analytics / skuId:2AE5-7980-8EDC
    const dimensionsString = keyString.split(": ").slice(1).join("");
    const dimensionsStrings = dimensionsString.split(" / ");

    const filters = dimensionsStrings.map((dimensionString) => {
      const [name] = dimensionString.split(":").slice(0);
      const values = dimensionString.split(":").slice(1);
      const operator = Operator.EQUALS;
      return { name, operator, values };
    });

    props.onInteraction({
      filters,
      type: PieChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED,
    });
  }

  //
  // JSX
  //

  if (props.isLoading || props.data.length === 0) {
    return (
      <EmptyPlaceholder
        loading={props.isLoading}
        icon={faPieChart}
        skeletonVariant="pie"
        text={copyText.chartEmptyPlaceholderText}
      />
    );
  }

  return (
    <StyledBox height="100%" position="relative" width="100%">
      <ChartWrapper isServer={props.isServer}>
        <ReactPieChart
          height={props.isServer ? REPORT_PDF_CHART_HEIGHT : undefined}
          margin={{
            bottom: props.isServer ? 10 : undefined,
            top: props.isServer ? 10 : undefined,
            left: 80,
            right: 80,
          }}
          width={props.isServer ? REPORT_PDF_CHART_WIDTH : undefined}
        >
          {props.showTooltip && !props.showExperimentalTooltip && (
            <Tooltip
              content={({ payload }) => {
                if (!payload || payload.length === 0) return null;

                const datum = payload[0];

                if (
                  typeof datum.name !== "string" ||
                  typeof datum.value !== "number"
                ) {
                  return null;
                }

                const measureName = datum.name.split(":")[0];
                const measure =
                  props.measures.length === 1
                    ? props.measures[0]
                    : props.measures.find(
                        (measure) => measure.name === measureName
                      );

                const unitType = measure // TODO: Determine if this should be the whole props.measures
                  ? getUniformUnitType([measure])
                  : undefined;

                return (
                  <Box
                    backgroundColor={theme.tooltip_background_color}
                    color={theme.tooltip_text_color}
                    padding={theme.space_sm}
                  >
                    <Flex
                      fontSize={theme.fontSize_small}
                      justifyContent="space-between"
                      marginBottom={theme.space_xs}
                    >
                      <div>
                        {props.readableKeys
                          ? (props.readableKeys[datum.name] ?? datum.name)
                          : datum.name}
                      </div>
                      <Box marginLeft={theme.space_sm}>
                        ({formatPercentage(datum.value / overallTotal)})
                      </Box>
                    </Flex>
                    <Flex justifyContent="flex-end">
                      <div>
                        {formatMeasureValueWithUnit({
                          currencyCode: props.currencyCode,
                          unit: unitType,
                          value: datum.value,
                        })}
                      </div>
                    </Flex>
                  </Box>
                );
              }}
              wrapperStyle={{ outline: "none" }}
            />
          )}
          {props.showExperimentalTooltip && (
            <Tooltip
              content={({ payload }) => {
                if (!payload || payload.length === 0) return null;
                const datum = payload[0];

                if (
                  typeof datum.name !== "string" ||
                  typeof datum.value !== "number"
                ) {
                  return null;
                }

                let readableMeasureName = "";
                let datumName = datum.name;
                let readableDimensionNames;

                if (props.dimensions.length > 1) {
                  let dimensionNames: string = "";

                  props.measures.forEach((measure) => {
                    if (datumName.indexOf(measure.name) === 0) {
                      readableMeasureName = measure.name;

                      const measureNameLength = measure.name.length;

                      dimensionNames = datumName.substring(
                        measureNameLength + 1
                      );
                    }
                  });

                  const dimensionNamesAndValuesArray =
                    dimensionNames.split(" / ");

                  const dimensionValuesArray = dimensionNamesAndValuesArray.map(
                    (element) => {
                      const key = element.split(":")[1].trim();
                      return props.readableKeys
                        ? (props.readableKeys[key] ?? key)
                        : key;
                    }
                  );

                  readableDimensionNames = dimensionValuesArray.join(" / ");
                } else if (props.dimensions.length === 1) {
                  const splitNames = datumName.split(":");

                  readableMeasureName =
                    splitNames.length > 1
                      ? splitNames[0]
                      : props.measures[0].name;

                  readableDimensionNames =
                    splitNames.length > 1 ? splitNames[1] : datumName;
                } else {
                  readableMeasureName = datumName;
                  readableDimensionNames = "";
                }

                const measure =
                  props.measures.length === 1
                    ? props.measures[0]
                    : props.measures.find(
                        (measure) => measure.name === readableMeasureName
                      );

                const unitType = measure // TODO: Determine if this should be the whole props.measures
                  ? getUniformUnitType([measure])
                  : undefined;

                return (
                  <Box pointerEvents="none" padding={theme.space_xs}>
                    <Flex
                      alignItems="center"
                      backgroundColor={theme.panel_backgroundColor}
                      borderRadius={theme.borderRadius_2}
                      boxShadow={`0 0 0 2px ${theme.border_color}`}
                      height={120}
                      padding={theme.space_sm}
                    >
                      <Box
                        backgroundColor={datum.payload.fill}
                        borderRadius={theme.borderRadius_2}
                        height="100%"
                        marginRight={theme.space_xs}
                        width={5}
                      />
                      <Box height="90%">
                        <Text bold>{readableMeasureName}</Text>
                        <Text>{readableDimensionNames}</Text>
                        <Text
                          fontSize={theme.fontSize_small}
                          marginVertical={theme.space_xxs}
                        >
                          {formatPercentage(datum.value / overallTotal)}
                        </Text>
                        <Text>
                          {formatMeasureValueWithUnit({
                            currencyCode: props.currencyCode,
                            unit: unitType,
                            value: datum.value,
                          })}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                );
              }}
              wrapperStyle={{ outline: "none" }}
            />
          )}
          <Pie
            isAnimationActive={false}
            data={chartData}
            dataKey={SLICE_VALUE_KEY}
            label={({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
              const datum = chartData[index];

              if (!datum) return;

              const RADIAN = Math.PI / 180;
              const radius =
                25 +
                Number(innerRadius) +
                (Number(outerRadius) - Number(innerRadius));
              const x = Number(cx) + radius * Math.cos(-midAngle * RADIAN);
              const y = Number(cy) + radius * Math.sin(-midAngle * RADIAN);

              const value = datum[SLICE_VALUE_KEY];

              if (typeof value !== "number") return;

              // Early exit for small slices
              if (value / overallTotal < 0.01) {
                return null;
              }

              return (
                <text
                  x={x}
                  y={y}
                  fill={theme.text_color}
                  textAnchor={x > cx ? "start" : "end"}
                  dominantBaseline="central"
                  fontSize={
                    props.showLegend ? theme.fontSize_ui : theme.fontSize_small
                  }
                >
                  {props.showFullLabel && datum[SLICE_LABEL_KEY]} (
                  {formatPercentage(value / overallTotal)})
                </text>
              );
            }}
            nameKey={SLICE_LABEL_KEY}
          >
            {chartData.map((datum, i) => {
              const propColors = isImpactMode
                ? ECO_DATA_VIZ_COLORS
                : theme.data_visualization_colors;

              const color = getColorByReverseIndex(
                chartData.length,
                i,
                propColors
              );

              const key = datum[SLICE_LABEL_KEY];

              if (typeof key !== "string") {
                return null;
              }

              return (
                <Cell
                  key={key}
                  fill={color}
                  fillOpacity={theme.chart_fill_opacity}
                  stroke={color}
                  style={{
                    cursor:
                      props.disableDrilldown || key.includes(NOT_SHOWN_KEY)
                        ? "not-allowed"
                        : props.dimensions.length > 0 ||
                            props.measures.length > 0
                          ? "pointer"
                          : "normal",
                  }}
                  onClick={
                    props.disableDrilldown || key.includes(NOT_SHOWN_KEY)
                      ? noop
                      : () => handleClickArea(key)
                  }
                />
              );
            })}
          </Pie>
          {props.showLegend && (
            <Legend
              iconType="square"
              verticalAlign="bottom"
              wrapperStyle={
                props.isServer
                  ? {
                      position: "relative",
                      overflow: "visible",
                    }
                  : undefined
              }
            />
          )}
        </ReactPieChart>
      </ChartWrapper>
    </StyledBox>
  );
}

PieChart.defaultProps = {
  dimensions: [],
};

PieChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED =
  `PieChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED` as const;

PieChart.INTERACTION_ADD_MEASURES_FILTER_CLICKED =
  `PieChart.INTERACTION_ADD_MEASURES_FILTER_CLICKED` as const;

interface InteractionAddGroupingFilterClicked {
  type: typeof PieChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED;
  filters: Filter[];
}
interface InteractionAddMeasuresFilterClicked {
  type: typeof PieChart.INTERACTION_ADD_MEASURES_FILTER_CLICKED;
  filters: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace PieChart {
  export type Interaction =
    | InteractionAddGroupingFilterClicked
    | InteractionAddMeasuresFilterClicked;
}

export default PieChart;
