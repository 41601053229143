import { keyBy } from "lodash";
import copyText from "../common.copyText";

//NOTE: Please add new applicable paths as options in navSearch.ts
const paths = {
  _admin: "/admin",
  _alertRuleFeed: "/alert-tracking/:alertRuleID",
  _alertTracking: "/alert-tracking",
  _budgets: "/budgets",
  _case: "/cases/:caseID",
  _cases: "/cases",
  _commitmentDiscountAWS: "/commitment-discount/aws",
  _commitmentDiscountAzure: "/commitment-discount/azure",
  _commitmentDiscountGCP: "/commitment-discount/gcp",
  _costAssistCostCompare: "/cost-assist/cost-compare",
  _costAssistCostCompareTakeout: "/cost-assist/cost-compare/:billID",
  _costAssistRampPlans: "/cost-assist/ramp-plans",
  _costAssistReallocations: "cost-assist/reallocations",
  _costReports: "/cost-reports",
  _customLabels: "/custom-labels",
  _dashboard: "/dashboards/:dashboardID",
  _dashboards: "/dashboards",
  _forecasting: "/forecasting",
  _globalAdmin: "/global-admin",
  _home: "/",
  _insightsBigQuery: "/insights/data-warehouse/big-query",
  _insightsCompute: "/insights/compute",
  _insightsComputeAzureVM: "/insights/compute/azure-vm",
  _insightsComputeCloudRun: "/insights/compute/cloud-run",
  _insightsComputeEC2: "/insights/compute/ec2",
  _insightsComputeGCE: "/insights/compute/gce",
  _insightsDatabase: "/insights/database",
  _insightsDatabaseAzureSQL: "/insights/database/azure-sql",
  _insightsDatabaseCloudSQL: "/insights/database/cloud-sql",
  _insightsDatabaseElastiCache: "/insights/database/elasticache",
  _insightsDatabaseMemoryDB: "/insights/database/memory-db",
  _insightsDatabaseOpenSearch: "/insights/database/open-search",
  _insightsDatabaseRDS: "/insights/database/rds",
  _insightsDatabaseSnowflake: "/insights/database/snowflake",
  _insightsDatabaseSpanner: "/insights/database/spanner",
  _insightsDataWarehouse: "/insights/data-warehouse",
  _insightsDataWarehouseSnowflake: "/insights/data-warehouse/snowflake",
  _insightsDataWarehousRedshift: "/insights/data-warehouse/redshift",
  _insightsKubernetes: "/insights/kubernetes",
  _insightsKubernetesAKS: "/insights/kubernetes/aks",
  _insightsKubernetesEKS: "/insights/kubernetes/eks",
  _insightsKubernetesGKE: "/insights/kubernetes/gke",
  _insightsStorage: "/insights/storage",
  _insightsStorageBB: "/insights/storage/bb",
  _insightsStorageEBS: "/insights/storage/ebs",
  _insightsStorageGCS: "/insights/storage/gcs",
  _insightsStorageS3: "/insights/storage/s3",
  _internalAdmin: "/internal",
  _internalMspPartnerTenants: "/internal/msp-partner/:parentTenantID/tenants",
  _internalTenantUsers: "/internal/tenants/:tenantID/users",
  _labelGroupingRules: "/label-grouping-rules",
  _mspAdminTenant: "/msp/admin/:parentTenantID",
  _mspAdminTenantUsers: "/msp/admin/tenants/:tenantID/users",
  _mspBillingRulesEngine: "/msp/billing-rules-engine",
  _mspDashboard: "/msp/dashboard",
  _mspMgmt: "/msp",
  _report: "/reports/:reportID",
  _reportBuilder: "/report-builder/:reportID",
  _reportBuilderNew: "/report-builder",
  _reports: "/reports",
  _settings: "/settings",
} as const;

export type Path = (typeof paths)[keyof typeof paths];

// NOTE: enableAddToCase should only be true for pages with reports
type LocationState = {
  path: Path;
  enableCaseManagement?:
    | boolean
    | { enableAddToCase?: boolean; enabledForTab?: string };
  enableGlobalFiltering?: boolean;
  enableGlobalDate?: boolean;
  title?: string;
};

// State for each route. Ordered alphabetically.
const locationStates: LocationState[] = [
  {
    path: paths._admin,
    title: copyText.adminPageTitle,
  },
  {
    path: paths._alertTracking,
    enableCaseManagement: true,
    enableGlobalDate: true,
    title: copyText.anomalyDetectionPageTitle,
  },
  {
    path: paths._budgets,
    enableCaseManagement: true,
    title: copyText.budgetsPageTitle,
  },
  {
    path: paths._cases,
    title: copyText.caseManagementPageTitle,
  },
  {
    path: paths._commitmentDiscountAzure,
    title: copyText.committedDiscountAzurePageTitle,
  },
  {
    path: paths._commitmentDiscountGCP,
    title: copyText.committedDiscountsGCPPageTitle,
  },
  {
    path: paths._commitmentDiscountAWS,
    title: copyText.committedDiscountAWSPageTitle,
  },
  {
    path: paths._costAssistCostCompare,
    title: copyText.costComparePageTitle,
  },
  {
    path: paths._costAssistCostCompareTakeout,
    title: copyText.costComparePageTitle,
  },
  {
    path: paths._costAssistRampPlans,
    enableCaseManagement: true,
    title: copyText.rampPlansPageTitle,
  },
  {
    path: paths._costAssistReallocations,
    title: copyText.reallocationsPageTitle,
  },
  {
    path: paths._costReports,
    enableGlobalDate: false,
    enableGlobalFiltering: true,
    title: copyText.costReportsPageTitle,
  },
  {
    path: paths._labelGroupingRules,
    title: copyText.labelGroupingRulesPageTitle,
  },
  {
    path: paths._customLabels,
    title: copyText.customLabelsPageTitle,
  },
  {
    path: paths._dashboard,
    enableCaseManagement: { enableAddToCase: true },
    enableGlobalDate: true,
    enableGlobalFiltering: true,
  },
  {
    path: paths._dashboards,
    enableGlobalDate: false,
  },
  {
    path: paths._home,
    enableCaseManagement: { enableAddToCase: true },
    enableGlobalDate: true,
    enableGlobalFiltering: true,
  },
  {
    path: paths._insightsBigQuery,
    enableGlobalDate: true,
    enableCaseManagement: true,
  },
  {
    path: paths._insightsCompute,
    enableGlobalDate: true,
    title: copyText.computeInsightsPageTitle,
  },
  {
    path: paths._insightsComputeAzureVM,
    enableGlobalDate: true,
    enableCaseManagement: true,
  },
  {
    path: paths._insightsComputeEC2,
    enableGlobalDate: true,
    enableCaseManagement: true,
  },
  {
    path: paths._insightsComputeGCE,
    enableGlobalDate: true,
    enableCaseManagement: true,
  },
  {
    path: paths._insightsDatabase,
    enableGlobalDate: true,
    title: copyText.databaseInsightsPageTitle,
  },
  {
    path: paths._insightsDatabaseSnowflake,
    enableGlobalDate: true,
  },
  {
    path: paths._insightsDatabaseCloudSQL,
    enableCaseManagement: true,
    enableGlobalDate: true,
  },
  {
    path: paths._insightsDatabaseSpanner,
    enableCaseManagement: true,
    enableGlobalDate: true,
  },
  {
    path: paths._insightsDatabaseOpenSearch,
    enableCaseManagement: true,
    enableGlobalDate: true,
  },
  {
    path: paths._insightsDatabaseRDS,
    enableCaseManagement: { enabledForTab: "optimizations" },
    enableGlobalDate: true,
  },
  {
    path: paths._insightsDataWarehouse,
    title: copyText.dataWarehouseInsightsPageTitle,
    enableGlobalDate: true,
  },
  {
    path: paths._insightsKubernetes,
    enableGlobalFiltering: true,
    enableGlobalDate: true,
    title: copyText.kubernetesInsightsPageTitle,
  },
  {
    path: paths._insightsKubernetesEKS,
    enableGlobalDate: true,
    enableCaseManagement: true,
  },
  {
    path: paths._insightsKubernetesGKE,
    enableGlobalDate: false,
    enableCaseManagement: true,
  },
  {
    path: paths._insightsDataWarehouseSnowflake,
    enableGlobalDate: true,
    title: copyText.snowflakeTitlePage,
  },
  {
    path: paths._insightsStorage,
    enableGlobalDate: true,
    title: copyText.storageInsightsPageTitle,
  },
  {
    path: paths._insightsStorageBB,
    enableGlobalDate: true,
    enableCaseManagement: true,
  },
  {
    path: paths._insightsStorageEBS,
    enableCaseManagement: { enabledForTab: "optimizations" },
    enableGlobalDate: true,
  },
  {
    path: paths._insightsStorageS3,
    enableCaseManagement: { enabledForTab: "optimizations" },
    enableGlobalDate: true,
  },
  {
    path: paths._insightsStorageGCS,
    enableCaseManagement: { enabledForTab: "optimizations" },
    enableGlobalDate: true,
  },
  { path: paths._internalAdmin, title: copyText.internalAdminTitle },
  {
    path: paths._internalMspPartnerTenants,
    title: copyText.internalAdminTitle,
  },
  { path: paths._internalTenantUsers, title: copyText.internalAdminTitle },
  { path: paths._mspMgmt, title: copyText.mspAdminTitle },
  { path: paths._mspAdminTenant, title: copyText.mspAdminTitle },
  { path: paths._mspAdminTenantUsers, title: copyText.mspAdminTitle },
  {
    path: paths._report,
    enableCaseManagement: { enableAddToCase: true },
    enableGlobalDate: true,
    enableGlobalFiltering: true,
  },
  {
    path: paths._reports,
    enableCaseManagement: false,
    enableGlobalDate: false,
    enableGlobalFiltering: false,
  },
  {
    path: paths._reportBuilder,
    enableCaseManagement: { enableAddToCase: true },
    enableGlobalDate: true,
    enableGlobalFiltering: true,
  },
  {
    path: paths._reportBuilderNew,
    enableCaseManagement: false,
    enableGlobalDate: true,
    enableGlobalFiltering: true,
  },
  {
    path: paths._settings,
    title: copyText.userSettingsManagementPageTitle,
  },
];

export const locationStateKeyedByPath = keyBy<LocationState | undefined>(
  locationStates,
  "path"
);

export default paths;
