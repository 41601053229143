import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { noop } from "lodash";
import React, { useState, useTransition } from "react";
import {
  CartesianGrid,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  Legend as _Legend,
  LineChart as _LineChart,
} from "recharts";
import { fiscalGranularities } from "../../analytics/fiscalDateUtils";
import { UnitType } from "../../constants/analytics";
import { ChartType, Operator, TimeGranularity } from "../../constants/enums";
import { ActivityTracker, actions } from "../../telemetry";
import {
  Dimension,
  Filter,
  Measure,
  RawData,
  ReadableKeys,
} from "../charts/types";
import {
  COMPARISON_KEY,
  DEFAULT_X_AXIS_KEY,
  NOT_SHOWN_KEY,
  PERCENTAGE_Y_AXIS_ID,
  PERCENT_DIFFERENCE_KEY,
  RAW_DIFFERENCE_KEY,
  REPORT_PDF_CHART_HEIGHT,
  REPORT_PDF_CHART_WIDTH,
  formatMeasureValueWithUnit,
  formatTimestamp,
  getColorByReverseIndex,
  getGreenColors,
  getIsDashedMeasure,
  getTicks,
  getUniformUnitType,
} from "../charts/utils";
import Box from "../components/Box";
import EmptyPlaceholder from "../components/EmptyPlaceholder";
import copyText from "../copyText";
import { ECO_DATA_VIZ_COLORS } from "../theme/default";
import { useChartDataManager } from "../utils/ChartDataManager";
import { getFormatForGranularity } from "../utils/dates";
import ActiveDot from "./ActiveDot";
import { ChartWrapper } from "./ChartWrapper";
import ExperimentalTooltip from "./ExperimentalTooltip";
import Legend from "./Legend";
import LegendSimple from "./LegendSimple";
import LegendTable from "./LegendTable";
import {
  cartesianStyleProps,
  lineStyleProps,
  xAxisStyleProps,
  yAxisStyleProps,
} from "./styles";
import { getTooltip } from "./TooltipTable";
import { useHorizontalLine, useTooltipCollector } from "./TooltipUtils";
import YAxisTick from "./YAxisTick";

interface Props {
  activityTracker?: ActivityTracker;
  currencyCode?: string;
  data: RawData[];
  dimensions: Dimension[];
  disableDrilldown?: boolean;
  excludeOther?: boolean;
  hideSubtotal?: boolean;
  hideTotal?: boolean;
  isComparisonMode?: boolean;
  isDataSorted?: boolean;
  isEcoImpactMode?: boolean;
  isFiscalMode?: boolean;
  isLoading: boolean;
  isServer?: boolean;
  limit?: number | null;
  measures: Measure[];
  readableKeys?: ReadableKeys;
  showExperimentalTooltip?: boolean;
  showLegend?: boolean;
  showTooltip?: boolean;
  timeSeriesGranularity: TimeGranularity;
  tooltipLimit?: number;
  xAxisKey?: string;
  tooltipFormatter?: (value: any, grouping: string) => string;
  onInteraction?: (interaction: LineChart.Interaction) => void;
}

const StyledBox = styled(Box)`
  .recharts-legend-wrapper {
    max-height: 8rem;
    overflow-y: auto;
  }

  .recharts-legend-item {
    color: ${(props) => props.theme.text_color};
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: ${({ theme }) => theme.space_xs};

    .recharts-symbols {
      d: path("M -8 -16 h 32 v 32 h -32 Z");
    }
  }

  span.recharts-legend-item-text {
    color: ${({ theme }) => theme.text_color} !important;
    display: block;
    font-size: ${({ theme }) => theme.fontSize_ui};
    /* Bring in the below if legend key lengths become an issue */
    /* max-width: 40rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }

  .recharts-default-legend {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;

    li {
      cursor: pointer;
    }
  }
`;

const AXIS_CHARACTER_WIDTH = 7;
const AXIS_MAX_LEFT_MARGIN = AXIS_CHARACTER_WIDTH * 10;
const DEFAULT_MAX_ALLOWED_LINES = 50; // This concept doesn't work with stacked charts
const DEFAULT_Y_AXIS_ID = "DEFAULT_Y_AXIS_ID";

export function LineChart(props: Props): JSX.Element {
  const theme = useTheme();

  const MAX_ALLOWED_LINES = props.limit
    ? props.limit
    : DEFAULT_MAX_ALLOWED_LINES;
  const xAxisKey = props.xAxisKey ?? DEFAULT_X_AXIS_KEY;

  const [isPending, startTransition] = useTransition();

  const [excludedKeys, setExcludedKeys] = useState<string[]>([]);
  const [tooltipDataKey, setTooltipDataKey] = useState<string | undefined>(
    undefined
  );

  //
  // Custom Elements
  //

  const horizontalLineElement = useHorizontalLine();
  const [tooltipCollectorElement, getCursorValue] =
    useTooltipCollector(DEFAULT_Y_AXIS_ID);

  //
  // Data
  //

  const dataManager = useChartDataManager({
    data: props.data,
    dimensions: props.dimensions,
    excludedChartKeys: excludedKeys,
    excludeOther: props.excludeOther,
    isDataSorted: props.isDataSorted,
    maxGroupingCount: MAX_ALLOWED_LINES,
    measures: props.measures,
    xAxisKey,
  });

  const isImpactMode = props.isEcoImpactMode ?? false;

  const uniformLineMeasureUnit = getUniformUnitType(props.measures);

  const dynamicAxisColors = getGreenColors(isImpactMode, theme);

  const chartColors = isImpactMode
    ? ECO_DATA_VIZ_COLORS
    : theme.data_visualization_colors;

  function getColor(params: {
    excluded: boolean;
    key: string;
    index: number;
  }): string {
    if (params.excluded) {
      return theme.text_color_disabled;
    }

    const newIndex = dataManager.sortedChartKeys.indexOf(
      params.key.replace(COMPARISON_KEY, "")
    );

    if (props.isComparisonMode) {
      return getColorByReverseIndex(
        dataManager.sortedChartKeys.length,
        newIndex === -1 ? params.index : newIndex,
        chartColors
      );
    }

    return getColorByReverseIndex(
      dataManager.sortedChartKeys.length,
      params.index,
      chartColors
    );
  }

  //
  // Handlers
  //

  function handleClickLine(chartKey: string) {
    const dimensionsWithValues =
      dataManager.getDimensionValuesFromChartKey(chartKey);
    const measure = dataManager.getMeasure(chartKey);

    if (
      !props.onInteraction ||
      !measure ||
      (dataManager.dimensions.length === 0 && dataManager.measures.length === 1)
    ) {
      return;
    }

    if (
      dataManager.dimensions.length === 0 &&
      dataManager.measures.length > 1
    ) {
      props.onInteraction({
        type: LineChart.INTERACTION_MEASURE_CLICKED,
        measure,
      });
      return;
    }

    props.onInteraction({
      type: LineChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED,
      filters: dimensionsWithValues.map(({ dimension, value }) => ({
        name: dimension.name,
        operator: Operator.EQUALS,
        values: [value],
      })),
    });
  }

  function handleInteraction(
    interaction: LegendSimple.Interaction | LegendTable.Interaction
  ) {
    switch (interaction.type) {
      case LegendSimple.INTERACTION_CHART_EXCLUSION_CHANGED:
      case LegendTable.INTERACTION_CHART_EXCLUSION_CHANGED: {
        if (props.activityTracker) {
          props.activityTracker.captureAction(
            actions.TOGGLE_CHART_LEGEND_ITEM,
            { keys: interaction.excludedChartKeys }
          );
        }

        setExcludedKeys(interaction.excludedChartKeys);
        break;
      }
      default:
        break;
    }
  }

  //
  // JSX
  //

  if (props.isLoading || props.data.length === 0) {
    return (
      <EmptyPlaceholder
        loading={props.isLoading}
        icon={faChartLine}
        skeletonVariant="cartesian"
        text={copyText.chartEmptyPlaceholderText}
      />
    );
  }

  let leftMargin =
    AXIS_CHARACTER_WIDTH *
    Math.round(dataManager.maxValue ?? 100).toString().length;
  if (leftMargin > AXIS_MAX_LEFT_MARGIN) {
    leftMargin = AXIS_MAX_LEFT_MARGIN;
  }

  const dateFormat = getFormatForGranularity(props.timeSeriesGranularity);

  const xAxisFormatter = (value: string) =>
    xAxisKey === DEFAULT_X_AXIS_KEY &&
    (!props.isFiscalMode ||
      !fiscalGranularities.includes(props.timeSeriesGranularity))
      ? formatTimestamp(value, dateFormat)
      : value;

  return (
    <StyledBox
      backgroundColor={theme.panel_backgroundColor}
      height="100%"
      position="relative"
      width="100%"
    >
      <ChartWrapper isServer={props.isServer}>
        <_LineChart
          data={dataManager.chartData}
          height={props.isServer ? REPORT_PDF_CHART_HEIGHT : undefined}
          margin={{
            top: props.isServer ? 10 : undefined,
            left: leftMargin,
            right: 25,
          }}
          width={props.isServer ? REPORT_PDF_CHART_WIDTH : undefined}
        >
          {tooltipCollectorElement}
          <CartesianGrid
            {...cartesianStyleProps}
            stroke={theme.chart_cartesian_grid_lines}
          />
          <XAxis
            {...xAxisStyleProps}
            stroke={dynamicAxisColors.axis}
            dataKey={xAxisKey}
            tick={{
              stroke: theme.chart_axis_text,
              fontWeight: 100,
              fontSize: "0.8rem",
            }}
            ticks={
              props.isServer
                ? getTicks(dataManager.chartData, xAxisKey)
                : undefined
            }
            tickFormatter={xAxisFormatter}
          />
          <YAxis
            domain={[0, "auto"]}
            {...yAxisStyleProps}
            stroke={dynamicAxisColors.axis}
            tick={<YAxisTick />}
            tickCount={8}
            tickFormatter={(value) =>
              formatMeasureValueWithUnit({
                currencyCode: props.currencyCode,
                unit: uniformLineMeasureUnit,
                value,
              })
            }
            yAxisId={DEFAULT_Y_AXIS_ID}
          />
          <YAxis domain={[0, 100]} hide yAxisId={PERCENTAGE_Y_AXIS_ID} />

          {dataManager.sortedChartKeys.map((key, index) => {
            if (
              key.includes(PERCENT_DIFFERENCE_KEY) ||
              key.includes(RAW_DIFFERENCE_KEY)
            ) {
              return null;
            }

            const excluded = excludedKeys.includes(key);
            const measure = dataManager.getMeasure(key);
            const isDashedMeasure = getIsDashedMeasure(measure?.name);
            const isPercentage = measure?.unit === UnitType.PERCENTAGE;

            const color = getColor({
              excluded: excluded,
              index,
              key,
            });

            const cursor =
              props.disableDrilldown || key.includes(NOT_SHOWN_KEY)
                ? "not-allowed"
                : props.dimensions.length > 0
                  ? "pointer"
                  : "normal";

            const onClick =
              props.disableDrilldown || key.includes(NOT_SHOWN_KEY)
                ? noop
                : () => handleClickLine(key);

            return (
              <Line
                {...lineStyleProps}
                activeDot={
                  key === tooltipDataKey && (
                    <ActiveDot cursor={cursor} onClick={onClick} />
                  )
                }
                key={key}
                dataKey={key}
                stroke={color}
                strokeDasharray={isDashedMeasure ? "5 5" : undefined}
                {...(excluded ? { strokeWidth: 0 } : { strokeWidth: 2.5 })}
                yAxisId={
                  isPercentage ? PERCENTAGE_Y_AXIS_ID : DEFAULT_Y_AXIS_ID
                }
                style={{
                  cursor:
                    props.disableDrilldown || key.includes(NOT_SHOWN_KEY)
                      ? "not-allowed"
                      : props.dimensions.length > 0 || props.measures.length > 0
                        ? "pointer"
                        : "normal",
                }}
                onClick={
                  props.disableDrilldown || key.includes(NOT_SHOWN_KEY)
                    ? noop
                    : () => handleClickLine(key)
                }
                onMouseOver={() =>
                  startTransition(() => setTooltipDataKey(key))
                }
                onMouseLeave={() =>
                  startTransition(() => setTooltipDataKey(undefined))
                }
              />
            );
          })}
          {props.showLegend && (
            <_Legend
              content={
                <Legend
                  dataManager={dataManager}
                  isServer={props.isServer}
                  readableKeys={props.readableKeys}
                  onInteraction={handleInteraction}
                />
              }
              verticalAlign="bottom"
              wrapperStyle={
                props.isServer
                  ? { overflow: "visible", position: "relative" }
                  : undefined
              }
            />
          )}
          {horizontalLineElement}
          {props.showTooltip &&
            !props.showExperimentalTooltip &&
            getTooltip(
              {
                dataManager: dataManager,
                hideSubtotal: props.hideSubtotal,
                hideTotal: props.hideTotal,
                hoveredChartKey: isPending ? null : (tooltipDataKey ?? null),
                isImpactMode: isImpactMode,
                maxRows: props.tooltipLimit,
                readableKeys: props.readableKeys,
                xAxisFormatter: xAxisFormatter,
              },
              theme
            )}
          {props.showExperimentalTooltip && (
            <Tooltip
              content={
                <ExperimentalTooltip
                  chartType={ChartType.LINE}
                  dataManager={dataManager}
                  getCursorValue={getCursorValue}
                  granularity={
                    props.xAxisKey !== DEFAULT_X_AXIS_KEY
                      ? undefined
                      : props.timeSeriesGranularity
                  }
                  readableKeys={props.readableKeys}
                  setTooltipDataKey={(dataKey) =>
                    startTransition(() => setTooltipDataKey(dataKey))
                  }
                  xAxisFormatter={xAxisFormatter}
                />
              }
              cursor={false}
              offset={0}
            />
          )}
        </_LineChart>
      </ChartWrapper>
    </StyledBox>
  );
}

LineChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED =
  `LineChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED` as const;

LineChart.INTERACTION_MEASURE_CLICKED =
  `LineChart.INTERACTION_MEASURE_CLICKED` as const;

interface InteractionAddGroupingFilterClicked {
  type: typeof LineChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED;
  filters: Filter[];
}

interface InteractionMeasureClicked {
  type: typeof LineChart.INTERACTION_MEASURE_CLICKED;
  measure: Measure;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace LineChart {
  export type Interaction =
    | InteractionAddGroupingFilterClicked
    | InteractionMeasureClicked;
}

export default LineChart;
