import ErrorBoundary from "@/components/ErrorBoundary";
import Tabs from "@/ui-lib/components/Tabs";
import { TenantType } from "@ternary/api-lib/constants/enums";
import getMergeState from "@ternary/api-lib/ui-lib/charts/utils";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import TenantListModal from "../../global-admin/components/TenantListModal";
import useGetTenants from "../../global-admin/hooks/useGetTenants";
import copyText from "../copyText";
import MspCostAlertViewContainer from "./MspCostAlertViewContainer";
import MspDataIntegrationViewContainer from "./MspDataIntegrationViewContainer";
import MspRecommendationViewContainer from "./MspRecommendationViewContainer";
import MspReportingContainer from "./MspReportingContainer";
import MspUserViewContainer from "./MspUserViewContainer";

type Interaction = TenantListModal.Interaction;

export function MspDashboardPage(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "reporting"),
  });

  const [state, setState] = useState<{ parentTenantID: string | null }>({
    ...(gatekeeper.canAccessInternalAdmin
      ? { parentTenantID: null }
      : { parentTenantID: authenticatedUser.parentTenantID as string }),
  });

  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: _tenants = [], isLoading: isLoading } = useGetTenants({
    enabled: gatekeeper.canReadTenantsSystem && !state.parentTenantID,
  });

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case TenantListModal.INTERACTION_ROW_CLICKED: {
        mergeState({ parentTenantID: interaction.tenantID });
        return;
      }
    }
  }

  //
  // Render
  //

  if (!gatekeeper.canAccessMspAdmin) {
    return <Navigate to={paths._home} replace />;
  }

  // If a Ternary System admin is attempting to access MSP Admin, they need to select
  // Which MSP Partner Tennat they want to look at first.
  if (!state.parentTenantID && gatekeeper.canReadTenantsSystem) {
    const tenants = _tenants.filter(
      (tenant) => tenant.type === TenantType.MSP_PARENT
    );

    return (
      <TenantListModal
        isLoading={isLoading}
        tenants={tenants}
        onInteraction={handleInteraction}
      />
    );
  }

  // parentTenantID will always be populated at this point.
  const parentTenantID = state.parentTenantID as string;

  const containers = [
    {
      component: <MspReportingContainer parentTenantID={parentTenantID} />,
      label: copyText.tabLabelReporting,
      value: "reporting",
    },
    {
      component: <MspCostAlertViewContainer parentTenantID={parentTenantID} />,
      label: copyText.tabLabelAnomalies,
      value: "anomalies",
    },
    {
      component: (
        <MspRecommendationViewContainer parentTenantID={parentTenantID} />
      ),
      label: copyText.tabLabelSavings,
      value: "savings",
    },
    {
      component: (
        <MspDataIntegrationViewContainer parentTenantID={parentTenantID} />
      ),
      label: copyText.tabLabelClouds,
      value: "clouds",
    },
    {
      component: <MspUserViewContainer parentTenantID={parentTenantID} />,
      label: copyText.tabLabelUsers,
      value: "users",
    },
  ];

  return (
    <ErrorBoundary boundaryName="MspAdminPage">
      <Tabs
        activeValue={searchParamState.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParamState({ tab })}
      />
    </ErrorBoundary>
  );
}

export default MspDashboardPage;
